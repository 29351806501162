import React, { useRef, useState } from 'react'
import homeimage from '../assets/Illustration 2 1.svg'
import flower from '../assets/flower.svg'
import flower1 from '../assets/Vector (1).svg'
import rectengle from '../assets/Rectangle 2037.svg'
import ellipse from '../assets/Ellipse 1.svg'
import ellipse1 from '../assets/Ellipse 2.svg'
import emailjs from '@emailjs/browser';


const Home = () => {
    const form = useRef();
    const email = useRef()
    
    const [submitted, setSubmitted] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_2ezkg0z', 'template_n9u995g', form.current, {
                publicKey: 'LTJkxZgL4pt3EGWm4',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    setSubmitted(true);
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };



    return (<>
        {/* <img src={flower} alt='flower'/> */}
        <div className='w-full '>

            <div className='flex flex-col lg:flex-row items-center md:justify-between'>

                {/* Text Section */}

                <div className='lg:w-[50%]'>
                    <img src={flower} alt='flower' className='res-hidden' />
                    <div className='md:ps-24 md:p-8 xs:p-4 md:text-left xs:text-center'>
                        <h1 className='md:text-4xl Poppins-bold text-2xl md:mb-2'>Reimagine Learning</h1>
                        <h2 className='md:text-2xl text-xl Poppins-bold mb-2 text-[#2989CA]'>Connect, Create, Conquer</h2>
                        <p className='sm:text-lg text-[15px] text-[#808080] Poppins-light mb-6'>
                            In today’s fast-paced digital world, education needs to be more engaging, collaborative, and accessible. Colabrary is a cutting-edge social learning platform designed to bring students, educators, and institutions together in a unified space. Whether you’re a school student, a college aspirant, or part of a coaching institute, Colabrary offers the tools and community you need to succeed.
                        </p>
                        <h1 className='sm:text-lg text-[15px] text-[#808080] Poppins-light mb-2'>Register Here </h1>
                        <div className='flex space-x-4 justify-center md:justify-start'>
                            {!submitted ? (
                                <form ref={form} onSubmit={sendEmail} className='space-x-2'>
                                    <input
                                        ref={email}
                                        type='email'
                                        name='from_email'
                                        placeholder='Enter Email'
                                        className='p-[5px] bg-transparent rounded-lg outline-none border border-[#E3904194]'
                                    />
                                    <button type='submit' className='btn-signlog py-2 bg-[#E3904194] Poppins-bold'>
                                        Subscribe
                                    </button>
                                </form>
                            ) : (
                                <p className='text-green-500'>We received your message and will contact you back soon.</p>
                            )}
                        </div>
                    </div>
                </div>

                {/* Image Section */}

                <div className='w-[50%] flex justify-center gap-4'>
                    <img src={flower1} alt='flower' className=' pb-6 w-14 h-24 self-end ' />
                    <img className='w-full md:w-[70%]' src={homeimage} alt='Reimagine Learning' />
                </div>

            </div>
            <div className='flex justify-center  items-center py-6 gap-4 lg:absolute bottom-[-93px] left-[620px] z-50'>
                <span className='span1'>
                    250
                    <br />
                    <span className='span2'>Total Instructor</span>
                </span>

                {/* Vertical Line */}
                <div className="vertical-line"></div>

                <span className='span1'>
                    100+
                    <br />
                    <span className='span2'>Collaboration</span>
                </span>
            </div>

            <div className="relative w-full res-hidden-xl">
                <img src={ellipse} alt="ellipse" className="left-[-40px] md:auto h-24 w-20 top-[174px] absolute z-20" />
                <img src={ellipse1} alt="ellipse1" className="right-0 md:w-auto w-8 h-24 lgt:top-[0px] top-[140px] absolute z-20" />
                <img src={rectengle} alt="rectengle" className="w-full bottom-[-176px]  absolute z-10" />
            </div>

        </div>
    </>)
}

export default Home








{/* <div className='flex space-x-4 justify-center md:justify-start'>
                            <button className='btn-signlog bg-[#E7CB4C] Poppins-bold'>SIGN UP  </button>
                            <button className='btn-signlog bg-[#E3904194] Poppins-bold'>Login</button>
                        </div> */}